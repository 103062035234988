<template>
  <div class="mobileCard">
     <mHead :name="'插卡取电'" :add="'/mobile/ProCard'" :adds="'/mobile/ProCardParam'" :color="true" />
    <div class="banner">
      <div class="bannerBox">
        <div class="title">插卡取电面板</div>
        <div class="text">咔哒一下，就有电</div>
      </div>
    </div>
    <div class="advantages">
      <div class="adv">
        <img src="../../assets/mobile/product/cardIcon1.png" class="advImg" />
        <div class="advTit">延时断电，安全方便</div>
      </div>
      <div class="adv">
        <img src="../../assets/mobile/product/cardIcon2.png" class="advImg" />
        <div class="advTit">优质材质，坚固耐用</div>
      </div>
      <div class="adv">
        <img src="../../assets/mobile/product/cardIcon3.png" class="advImg" />
        <div class="advTit">30A 大功率</div>
      </div>
      <div class="adv">
        <img src="../../assets/mobile/product/cardIcon4.png" class="advImg" />
        <div class="advTit">多重防护，保障安全</div>
      </div>
    </div>
    <div class="sec">
      <div class="secTit">多种颜色，多种精彩</div>
      <div class="imgBox">
        <img src="../../assets/mobile/product/card1.png" class="secImg" />
        <div class="name">白色</div>
      </div>
      <div class="imgBox">
        <img src="../../assets/mobile/product/card2.png" class="secImg" />
        <div class="name">黑色</div>
      </div>
      <div class="imgBox">
        <img src="../../assets/mobile/product/card3.png" class="secImg" />
        <div class="name">浅香槟金</div>
      </div>
    </div>
  </div>
</template>

<script>
import mHead from "../../components/MobileHead.vue";
export default {
  components: {
    mHead
  }
};
</script>
<style scoped lang="less">
.banner {
  width: 100%;
  height: 8rem;
  background-image: url(../../assets/mobile/product/cardBanner.png);
  background-size: 100% 100%;
  padding-top: 2rem;
  .bannerBox {
    padding-left: 0.39rem;
    font-size: 0.48rem;
    line-height: 0.48rem;
    font-weight: bold;
    color: #ffffff;
    text-align: left;
    .title {
      padding-top: 0.53rem;
    }
    .text {
      margin-top: 0.24rem;
      font-size: 0.3rem;
      line-height: 0.3rem;
      font-weight: 400;
    }
  }
}
.advantages {
  width: 100%;
  background-color: #333;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 0.24rem;
  line-height: 0.24rem;
  font-weight: bold;
  color: #ffffff;
  .adv {
    width: 50%;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:nth-of-type(1),
    &:nth-of-type(2) {
      padding-bottom: 0;
    }
    &:nth-of-type(1) {
      .advImg {
        width: 0.41rem;
        height: 0.41rem;
      }
    }
    &:nth-of-type(2) {
      .advImg {
        width: 0.45rem;
        height: 0.39rem;
      }
      .advTit {
        margin-top: 0.25rem;
      }
    }
    &:nth-of-type(3) {
      padding-top: 0.64rem;
      .advImg {
        width: 0.47rem;
        height: 0.36rem;
      }
      .advTit {
        margin-top: 0.27rem;
      }
    }
    &:nth-of-type(4) {
      padding-top: 0.64rem;
      .advImg {
        width: 0.38rem;
        height: 0.41rem;
      }
    }
    .advTit {
      margin-top: 0.24rem;
    }
  }
}
.sec {
  width: 100%;
  background-color: #fff;
  padding-top: 0.8rem;
  .secTit {
    margin-bottom: 0.39rem;
    font-size: 0.36rem;
    left: 0.36rem;
    font-weight: 800;
    color: #333333;
  }
  .imgBox {
    position: relative;
    width: 100%;
    height: 5rem;
    .secImg {
      width: 100%;
      height: 100%;
    }
    .name {
      position: absolute;
      bottom: 0.41rem;
      left: 0;
      width: 100%;
      text-align: center;
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 400;
      color: #ff9000;
    }
  }
}

</style>